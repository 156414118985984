import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

const SidebarStyles = styled.aside`
  .search {
    margin-bottom: 3rem;

    form {
      display: flex;
      flex-wrap: wrap;

      input,
      button {
        padding: 2rem;
        border: 1px solid var(--lgrey);
        font-size: 1.6rem;
        line-height: 2.1rem;
        border-radius: 5px;
      }

      input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        flex: 0 0 calc(70% - 4rem - 2px);
        min-width: 0;
      }

      button {
        background-color: var(--lgrey);
        color: #fff;
        cursor: pointer;
        transition: background-color 0.3s ease;
        border: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        flex: 0 0 calc(30% - 4rem);

        &:hover {
          background-color: var(--blue);
        }
      }
    }
  }

  .widget {
    h2 {
      font-size: 2.4rem;
      line-height: 4.5rem;
      font-weight: 500;
      color: var(--body);
    }

    h3 {
      font-weight: 400;
      font-size: 2.5rem;
      line-height: 3.5rem;
      word-wrap: break-word;
    }

    a {
      color: var(--body);

      &:hover {
        color: var(--blue);
      }
    }
  }

  @media screen and (max-width: 1080px) {
    .search {
      form {
        input {
          flex: 1 1 70%;
        }

        button {
          flex: 1 1 30%;
        }
      }
    }
  }
`

const Sidebar = ({ data }) => {
  const posts = useStaticQuery(graphql`
    query SidebarPosts {
      allWpPost(limit: 6) {
        edges {
          node {
            title
            slug
          }
        }
      }
    }
  `)

  return (
    <SidebarStyles>
      <div className="search">
        <form action="/search/">
          <input type="text" name="s" placeholder="Search" />
          <button>Search</button>
        </form>
      </div>

      {posts &&
      posts.allWpPost &&
      posts.allWpPost.edges &&
      posts.allWpPost.edges.length ? (
        <div className="widget">
          <h2>Recent Posts</h2>

          {posts.allWpPost.edges.map(post => {
            return (
              <Link to={`/${post.node.slug}`}>
                <h3>{post.node.title}</h3>
              </Link>
            )
          })}
        </div>
      ) : null}
    </SidebarStyles>
  )
}

export default Sidebar
