import React from "react"
import { graphql } from "gatsby"
import { useQueryParam, StringParam } from "use-query-params"
import Seo from "gatsby-plugin-wpgraphql-seo"
import Layout from "../components/layout"
import Category from "../components/category"

const SearchTemplate = ({ data: { wp, posts } }) => {
  const searchParam = useQueryParam("s", StringParam)
  const searchQuery =
    searchParam && searchParam[0] ? searchParam[0].toLowerCase() : null

  const filteredPosts = posts.edges.filter(post => {
    if (searchQuery) {
      if (
        (post.node.title &&
          post.node.title.toLowerCase().includes(searchQuery)) ||
        (post.node.content &&
          post.node.content.toLowerCase().includes(searchQuery))
      ) {
        return post
      }
    }
  })

  posts.edges = filteredPosts

  return (
    <Layout>
      <Seo
        title="Byers Scientific"
        postSchema={JSON.parse(wp.seo.contentTypes.post.schema.raw)}
      />
      <Category data={posts} />
    </Layout>
  )
}

export default SearchTemplate

export const pageQuery = graphql`
  query PostsBySearch {
    wp {
      seo {
        contentTypes {
          post {
            schema {
              raw
            }
          }
        }
      }
    }
    # selecting the current page by id
    posts: allWpPost {
      edges {
        node {
          title
          slug
          excerpt
          content
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
              sourceUrl
            }
          }
          tags {
            nodes {
              name
            }
          }
          categories {
            nodes {
              slug
              uri
              name
            }
          }
          acfResource {
            file {
              guid
            }
          }
          date(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`
