import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { BsArrowRight } from "react-icons/bs"
import Sidebar from "./sidebar"

const CategoryStyles = styled.section`
  .category {
    margin-bottom: 9rem;

    .row {
      .col {
        box-sizing: border-box;
      }

      .main {
        flex: 1 1 calc(75% - 6rem - 1px);
        padding: 6rem 6rem 6rem 0;
        border-right: 1px solid var(--bd);

        .post {
          margin-bottom: 6rem;

          img {
            display: block;
          }

          .gatsby-image-wrapper {
            /* padding-top: 66.6666%; */
          }

          a {
            color: var(--body);

            &:hover {
              color: var(--blue);
            }
          }

          h3 {
            font-weight: 400;
            font-size: 3rem;
            line-height: 5rem;
            margin: 3rem 0 2rem;
          }

          .meta {
            font-size: 1.8rem;
            line-height: 3.2rem;
          }

          p {
            font-size: 2.1rem;
            line-height: 3.5rem;
            margin: 3rem 0;
          }

          .more {
            color: var(--blue);
            font-size: 1.6rem;
            line-height: 2.1rem;
            font-weight: 600;

            svg {
              vertical-align: middle;
            }

            &:hover {
              color: var(--body);
            }
          }

          .sub-title {
            margin-bottom: 1.5rem;
          }
        }

        .pagination {
          display: flex;
          align-items: center;
          justify-content: space-between;

          a {
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 2.1rem;
          }
        }
      }

      .sidebar {
        flex: 1 1 calc(25% - 3rem);
        padding: 6rem 0 6rem 3rem;
      }
    }
  }

  @media screen and (max-width: 1080px) {
    .category {
      .row {
        flex: 1 1 100%;

        .main {
          padding-right: 0;
          border-right: 0;

          .post {
            img {
              width: 100%;
            }
          }
        }

        .sidebar {
          padding-left: 0;
          flex: 1 1 100%;
        }
      }
    }
  }
`

const Category = ({ data }) => {
  return (
    <CategoryStyles>
      <div className="category container">
        <div className="row">
          <div className="col main">
            {data && data.edges && data.edges.length ? (
              <React.Fragment>
                {data.edges.map(post => {
                  return (
                    <div className="post">
                      <Link to={`/${post.node.slug}`}>
                        {post.node.featuredImage &&
                        post.node.featuredImage.node &&
                        post.node.featuredImage.node.localFile &&
                        post.node.featuredImage.node.localFile
                          .childImageSharp &&
                        post.node.featuredImage.node.localFile.childImageSharp
                          .fluid ? (
                          <Img
                            fluid={
                              post.node.featuredImage.node.localFile
                                .childImageSharp.fluid
                            }
                            alt={post.node.featuredImage.node.altText}
                          />
                        ) : null}
                      </Link>

                      {post.node.acfResource &&
                      post.node.acfResource.file &&
                      post.node.acfResource.file.guid ? (
                        <a
                          href={post.node.acfResource.file.guid}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <h3>{post.node.title}</h3>
                        </a>
                      ) : (
                        <Link to={`/${post.node.slug}`} className="more">
                          <h3>{post.node.title}</h3>
                        </Link>
                      )}

                      <div className="meta">
                        by Byers Scientific | {post.node.date}
                        {post.node.categories &&
                        post.node.categories.nodes.length ? (
                          <React.Fragment>
                            {post.node.categories.nodes.map(cat => {
                              return (
                                <React.Fragment>
                                  {" "}
                                  | <Link to={cat.uri}>{cat.name}</Link>
                                </React.Fragment>
                              )
                            })}
                          </React.Fragment>
                        ) : null}
                      </div>

                      <div
                        dangerouslySetInnerHTML={{
                          __html: post.node.excerpt,
                        }}
                      ></div>

                      {post.node.acfResource &&
                      post.node.acfResource.file &&
                      post.node.acfResource.file.guid ? (
                        <a
                          href={post.node.acfResource.file.guid}
                          target="_blank"
                          rel="noreferrer"
                          className="more"
                        >
                          Read more <BsArrowRight />
                        </a>
                      ) : (
                        <Link to={`/${post.node.slug}`} className="more">
                          Read more <BsArrowRight />
                        </Link>
                      )}
                    </div>
                  )
                })}
              </React.Fragment>
            ) : (
              <div className="post">
                <p>No posts found.</p>
              </div>
            )}

            {/* <div class="pagination">
               <div class="prev">
                 <Link to="/category">« Older Entries</Link>
               </div>
               <div class="next">
                 <Link to="/category">Next Entries »</Link>
               </div>
             </div> */}
          </div>
          <div className="col sidebar">
            <Sidebar />
          </div>
        </div>
      </div>
    </CategoryStyles>
  )
}

export default Category
